
/deep/.tables tbody tr:hover >td  {
    background-color: unset !important;
}
/deep/.el-table.table-live-student {
    color: #455A94;
    background-color: #F8F9FB;
    font-weight: 500;
    th {
        color: #8CA5C6;
        background-color: #F2F3FA!important;
    }
    .el-table__row {
        height: 60px;
        border: 0px;
    }
    .el-link {
        margin-right: 24px;
        &:last-child {
            margin-right: 0;
        }
    }
    .el-table__body-wrapper {
        background-color: #ffffff;
    }
    tr.el-table__row:last-child .el-table__cell {
        border: none;
    }
    tr.el-table__row:last-child .el-table__cell {
        border: none;
    }
    td.el-table__cell:last-child {
        border: none;
    }
}
.data-content {
    display: flex;
    height: 100%;
    box-sizing: content-box;

    .left-content {
        //height: 860px;
        //width: 375px;
        height: 795px;
        width: 430px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 20px;
        background-image: url("../../../assets/images/live_background.png");
        background-repeat: no-repeat;
        background-size: cover;
    }
    .right-content {
        flex: 1;
        margin-left: 20px;
        overflow: hidden;

        .top-tips {
            width: 80px;
            //color: #fff;
            position: relative;

            &:before {
                //content: "*";
                //color: #ff3e6c;
                //margin-right: 4px;
                //position: absolute;
                //right: -10px;
                //top: 2px;
            }
        }
        .right-bottom {
            //margin-top: 30px;
            //min-height: 480px;
            height: 100%;
            .bottom-btn {
                text-align: right;
                margin-bottom: 13px;
            }
        }
    }
}

.left-inner {
    padding: 20px;
    height: calc(100% - 40px);
}

.top-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shadow-3 {
    background: rgba(0, 0, 0, 0.3);
}

.shadow-5 {
    background: rgba(0, 0, 0, 0.5);
}

.top-left {
    width: 202px;
    height: 44px;
    border-radius: 22px;
}

.user-info {
    height: 37px;
    padding: 2px;
    display: flex;
    justify-content: space-between;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .middle-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        color: #fff;
        margin: 4px 0 0 7px;

        .user-live-name {
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .user-live-like {
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .right-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px;
        width: 48px;
        height: 36px;
        background-color: #fe3266;
        border-radius: 18px;
        cursor: pointer;
        font-size: 15px;
        font-weight: bold;
        color: #fff;
    }

    .grey-btn {
        background: rgba(0, 0, 0, 0.3);
    }
}

.top-right {
    height: 44px;
    display: flex;
    align-items: center;

    .watch-num {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 13px;
        width: 46px;
        height: 28px;
        color: #fff;
        margin-right: 10px;
    }

    .close-content {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        i {
            font-size: 17px;
            color: #fff;
        }
    }
}

.tag-content {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;

    .tag-item {
        color: #fff;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px;
        margin-right: 9px;
    }
}

.preview-content {
    margin-top: 12px;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    img {
        width: 30%;
        height: auto;
    }
}

.upload-content {
    display: flex;
    margin-top: 12px;
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    justify-content: flex-start;

    &.table-upload {
        justify-content: center;

        .img-uploader {
            width: 100px;
            height: 100px;
        }
    }

    .upload-tips {
        display: flex;
        align-items: center;
        margin: 0 96px 0 20px;
    }

    .img-box {
        width: 108px;
        height: 192px;
        position: relative;

        &.img-100 {
            width: 100px;
            height: 100px;

            img {
                width: 100px;
                height: 100px;
            }
        }

        .shadow-box {
            position: absolute;
            bottom: 0;
            background: #000000;
            opacity: 0.6;
            border-radius: 4px;
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 12px;
            cursor: pointer;
        }

        img {
            width: 108px;
            height: 192px;
        }
    }

    .img-uploader {
        width: 108px;
        height: 192px;
        //background: #0c0e3f;
        border: 1px dashed #DCDFE6;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;
        border-radius: 4px;

        .add-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            line-height: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .add-icon {
                color: #DCDFE6;
                font-size: 24px;
            }
        }
    }

    .img-tips {
        position: absolute;
        height: 20px;
        left: 60px;
        //color: #ffffff;
        bottom: -30px;
    }
}

.main-table {
    //background: #070932;
}

.el-table {
    width: 100%;

    &:before {
        content: none;
    }

    ::v-deep .el-table__empty-block {
        display: none;
    }

    ::v-deep th.el-table__cell.is-leaf {
        border-bottom: none;
    }

    ::v-deep th.el-table__cell {
        &:first-child {
            & > .cell {
                padding-left: 70px;
            }
        }

        &.is-leaf {
            border-bottom: none;
        }
    }

    ::v-deep td.el-table__cell {
        &:first-child {
            & > .cell {
                padding-left: 70px;
            }
        }
    }

    ::v-deep .cell {
        overflow: unset;
    }
}

.download-box {
    display: flex;
    margin-top: 20px;
    align-items: center;

    img {
        width: 26px;
        height: 26px;
    }

    .download-text {
        margin: 0 20px 0 10px;
    }
}

.empty-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;

    .no-data {
        width: 322px;
        height: 271px;
    }

    .no-data-tips {
        margin: 58px 0 34px;
        color: #fff;
    }
}

.goods-select {
    width: 300px;

    ::v-deep .el-icon-arrow-up:before {
        content: "\e78f";
    }
}

.op-content {
    display: flex;
    justify-content: center;

    .op-btn + .op-btn {
        margin-left: 20px;
    }

    .op-btn {
        width: 76px;
        height: 30px;
        background: rgba(75, 69, 255, 0.1);
        border: 1px solid #4b45ff;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #4b45ff;
        cursor: pointer;

        &.danger {
            border: 1px solid #ff3e6c;
            background: rgba(255, 62, 108, 0.1);
            color: #ff3e6c;
        }
    }
}
